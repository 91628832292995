<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = withDefaults(defineProps<{
    position?: string,
  }>(), {
    position: 'left',
  })
  const show = ref(false)

  const positionClass = computed(() => {
    if (props.position === 'left') {
      return 'left-0 mt-2'
    } else if (props.position === 'right') {
      return 'right-0 mt-2'
    }
    return 'left-0 right-0 mt-2'
  })

  const expand = () => {
    show.value = true
  }
  const hide = () => {
    show.value = false
  }

  defineExpose({ expand, hide })
</script>

<template>
  <div v-click-outside="hide" class="relative">
    <button
      class="w-full"
      aria-haspopup="true"
      :aria-expanded="show"
      :to="{name: 'settings'}"
      @click.prevent="show = !show">
      <slot></slot>
    </button>
    <div
      v-if="show"
      class="absolute z-20 bg-white shadow"
      :class="positionClass">
      <slot name="menu"></slot>
    </div>
  </div>
</template>
