import RouterView from '@/functions/RenderEmptyRouterView'

export default {
  path: '/clients',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'clients',
      component: () => import('@/views/User/Index.vue'),
    },
    {
      path: '/client/:id/:name?',
      name: 'client show',
      props: true,
      component: () => import('@/views/User/Show/PageDetail.vue'),
    },
  ],
}