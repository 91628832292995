<script setup>
  import { computed } from 'vue'
  import { fmt } from '@/functions/formatters'

  const props = defineProps({
    classLabel: {
      type: [String, Object],
      default: '',
    },
    error: {
      type: [String, Array, Object],
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    showMaxLength: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 3,
    },
  })
  const emit = defineEmits(['update:modelValue'])

  const hasError = computed(() => props.error && props.error.length > 0)
  const value = computed({
    get: () => props.modelValue,
    set: value => {
      emit('update:modelValue', value)
    },
  })
</script>

<script>
  export default {
    name: 'FormInput',
    inheritAttrs: false,
  }
</script>

<template>
  <div
    class="relative py-1 my-2"
    :class="{'has-error': hasError}">
    <label
      :for="$attrs.id"
      class="text-xs text-gray-500"
      :class="classLabel">
      {{ $attrs.placeholder }}
    </label>
    <div class="flex items-end">
      <textarea
        v-if="type === 'textarea'"
        v-model="value"
        class="form-input"
        :rows="rows"
        :class="{'!pr-8': $attrs.maxlength && showMaxLength}"
        v-bind="$attrs"></textarea>
      <input
        v-else
        v-model="value"
        :type="type"
        class="form-input"
        :class="{'!pr-8': $attrs.maxlength && showMaxLength}"
        v-bind="$attrs">
      <div class="absolute right-0">
        <span v-if="$attrs.maxlength && showMaxLength" class="px-1 text-3xs bg-white">
          {{ fmt.number($attrs.maxlength - modelValue.length) }}
        </span>
      </div>
    </div>
    <g-error-msg :message="error" />
  </div>
</template>

<style lang="postcss" scoped>
.has-error {
  input, textarea {
    @apply border-b border-b-red-400;
  }
  label {
    @apply text-red-400;
  }
}
</style>

<style lang="postcss">
.form-input {
  @apply w-full py-1 text-sm font-medium placeholder-transparent focus:outline-none;
  @apply border-b border-gray-300 text-gray-800;
}
</style>