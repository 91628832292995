export default {
  path: '/transactions',
  name: 'credits',
  component: () => import('@/views/Transaction/Index.vue'),
  children: [
    {
      path: 'enquire',
      name: 'topup create inquiry',
      component: () => import('@/views/Transaction/Inquiry/CreateInquiry.vue'),
    },
    {
      path: 'topup-enquiries',
      name: 'topup inquiries',
      component: () => import('@/views/Transaction/Inquiry/Inquiries.vue'),
    },
    {
      path: 'history',
      name: 'credits history',
      component: () => import('@/views/Transaction/History.vue'),
    },
    {
      path: 'program-order',
      name: 'program order history',
      component: () => import('@/views/Transaction/ProgramOrderList.vue'),
    },
    {
      path: 'company/topup-enquiries',
      name: 'company topup inquiries',
      component: () => import('@/views/Transaction/Company/TopupInquiries.vue'),
    },
    {
      path: 'company/history',
      name: 'company credits history',
      component: () => import('@/views/Transaction/Company/CreditsHistory.vue'),
    },
    {
      path: 'company/topup/history',
      name: 'company topup history',
      component: () => import('@/views/Transaction/Company/TopupList.vue'),
    },
    {
      path: 'topup/history',
      name: 'topup history',
      component: () => import('@/views/Transaction/Topup/TopupList.vue'),
    },
    {
      path: 'payment/history',
      name: 'payment history',
      component: () => import('@/views/Transaction/PaymentHistory/PaymentHistory.vue'),
    },
    {
      path: 'topup',
      name: 'topup create',
      component: () => import('@/views/Transaction/Topup/TopupCreate.vue'),
    },
  ],
}