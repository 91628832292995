<script setup lang="ts">
  const emit = defineEmits<{
    (e: 'click'): void,
  }>()
</script>

<template>
  <div class="absolute top-5 right-4 text-gray-800 z-1">
    <button class="px-2 py-1" @click="emit('click')">
      <fa-icon icon="i-far-xmark" />
    </button>
  </div>
</template>
