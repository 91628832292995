import { addMinutes, format, formatDistanceToNowStrict } from 'date-fns'

const replaceTimeCustom = (date: Date, str: string) => {
  // date-fns default is 1-12 for AM and PM
  // this is to convert 1-12 AM to 0-11 for AM, but keep 1-12 PM
  if (format(date, 'a') === 'PM') {
    return str
  }

  return str.replace(format(date, 'hh:mm'), format(date, 'HH:mm'))
}

export function date (dt: unknown, fmt: string) {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  const d = new Date(dt)

  return replaceTimeCustom(d, format(d, fmt))
}
export function dateSchedule (dt: unknown) {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  return date(new Date(dt), 'EEE, dd MMM yyyy, hh:mm') + ' - '
    + date(addMinutes(new Date(dt), 20), 'hh:mm aaa')
}
export function dateScheduleExtended (dt: unknown) {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  return date(new Date(dt), 'EEE, dd MMM yyyy, hh:mm aaa') + ' - '
    + date(addMinutes(new Date(dt), 40), 'hh:mm aaa')
}

export function dateDiffHuman (dt: unknown) {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  return formatDistanceToNowStrict(new Date(dt), { addSuffix: true })
}
