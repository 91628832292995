<script setup lang="ts">
  import { ref, watchEffect } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import InputSearch from './InputSearch.vue'

  const route = useRoute()
  const router = useRouter()

  const emit = defineEmits<{
    (e: 'submit', data: string): void,
  }>()
  const search = ref('')

  const submitForm = () => {
    router.push({
      name: route.name ?? '',
      query: {
        ...route.query,
        search: search.value ? search.value : undefined,
      },
    })
    emit('submit', search.value)
  }

  watchEffect(() => {
    if (route.query.search !== search.value) {
      search.value = String(route.query.search ?? '')
      emit('submit', search.value)
    }
  })
</script>

<template>
  <input-search v-model="search" @submit="submitForm" />
</template>

<style lang="postcss" scoped>
</style>