import { createRouter, createWebHistory } from 'vue-router'
import { usePageStore } from '@/store/page'
import appointmentRoutes from './appointments'
import authRoutes from './auth'
import clientRoutes from './clients'
import coachesRoutes from './coaches'
import companyRoutes from './company'
import creditsRoutes from './credits'
import reviewRoutes from './review'
import settingsRoutes from './settings'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home/Index.vue'),
      },
      {
        path: '/schedules',
        name: 'schedules',
        component: () => import('@/views/Schedule/Index.vue'),
      },
      {
        path: '/goals',
        name: 'goals',
        component: () => import('@/views/Appointment/Goals/Index.vue'),
      },
      {
        path: '/f-to-l-submit/:userId',
        name: 'user f to l submit',
        props: true,
        component: () => import('@/views/UserFToLSubmit/History.vue'),
      },
      {
        path: '/who-5/:userId',
        name: 'who submit',
        props: true,
        component: () => import('@/views/UserWhoSubmit/HistoryWhoSubmit.vue'),
      },
      {
        path: '/who-5/form',
        name: 'who submit form',
        component: () => import('@/views/UserWhoSubmit/Form.vue'),
      },
      {
        path: '/inquiry-created',
        name: 'topup inquiry created',
        component: () => import('@/views/Transaction/Inquiry/InquiryCreated.vue'),
      },
      {
        path: '/topup-completed',
        name: 'topup completed',
        component: () => import('@/views/Transaction/Topup/TopupCompleted.vue'),
      },
      {
        path: '/notifications',
        name: 'notification',
        component: () => import('@/views/Notification/Index.vue'),
      },
      {
        path: '/redeem',
        name: 'redeem code',
        component: () => import('@/views/Redeem/PageRedeem.vue'),
      },
      {
        path: '/program-order/:id/paid',
        name: 'program order paid',
        props: true,
        component: () => import('@/views/ProgramOrder/PageHasBeenPaid.vue'),
      },
      {
        path: '/program-order/:id',
        name: 'program order show',
        props: true,
        component: () => import('@/views/ProgramOrder/PageShow.vue'),
      },
      {
        path: '/program-order/:orderId/domain/:domainId',
        name: 'program order book session',
        props: true,
        component: () => import('@/views/ProgramOrder/BookSession/PageIndex.vue'),
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/Error/Error503.vue'),
      },
      appointmentRoutes,
      coachesRoutes,
      settingsRoutes,
      clientRoutes,
      companyRoutes,
      creditsRoutes,
      reviewRoutes,
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('@/views/Error/Error404.vue'),
      },
    ],
  },
  authRoutes,
  {
    path: '/appointment/created/:id',
    name: 'appointment created',
    props: true,
    component: () => import('@/views/Appointment/Created.vue'),
  },
  {
    path: '/paypal/topup-credits/:credits(\\d+)',
    name: 'topup redirect paypal',
    props: true,
    component: () => import('@/views/Paypal/TopupRedirectPaypal.vue'),
  },
  {
    path: '/paypal/topup-offer/:inquiryId(\\d+)',
    name: 'topup offer redirect paypal',
    props: true,
    component: () => import('@/views/Paypal/TopupOfferRedirectPaypal.vue'),
  },
  {
    path: '/paypal/buy-program/:programId(\\d+)',
    name: 'buy program redirect paypal',
    props: true,
    component: () => import('@/views/Paypal/ProgramOrderRedirectPaypal.vue'),
  },
  {
    path: '/paypal/buy-wellness-bundle/:bundleId(\\d+)',
    name: 'buy wellness bundle redirect paypal',
    props: true,
    component: () => import('@/views/Paypal/WelnessBundleRedirectPaypal.vue'),
  },
  {
    path: '/paypal/topup/payment-completed',
    name: 'topup payment completed',
    component: () => import('@/views/Paypal/TopupPaymentCompleted.vue'),
  },
  {
    path: '/paypal/program/payment-completed',
    name: 'program payment completed',
    props: true,
    component: () => import('@/views/Paypal/ProgramOrderPaymentCompleted.vue'),
  },
  {
    path: '/paypal/wellness-bundle/payment-completed',
    name: 'welness bundle payment completed',
    props: true,
    component: () => import('@/views/Paypal/WellnessBundlePaymentCompleted.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  page.resetErrorState()
  next()
})

export default router