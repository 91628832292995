import RouterView from '@/functions/RenderEmptyRouterView'

export default {
  path: '/coaches',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'coaches',
      component: () => import('@/views/Coach/Index.vue'),
    },
    {
      path: '/coach/:id/:name?',
      name: 'coach show',
      props: true,
      component: () => import('@/views/Coach/Show.vue'),
    },
  ],
}