<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = withDefaults(defineProps<{
    src?: string|null,
    type?: 'person'|'question'|'default',
  }>(), {
    src: '',
    type: 'default',
  })
  const error = ref(false)

  watch(() => props.src, () => {
    error.value = false
  })
</script>

<template>
  <div>
    <div v-if="!src || error" class="flex items-center justify-center w-full h-full text-gray-500 bg-gray-200">
      <fa-icon v-if="type === 'person'" icon="i-far-user-tie" class="w-3/5 h-3/5 block" />
      <fa-icon v-else-if="type === 'question'" icon="i-far-question" class="w-3/5 h-3/5 block" />
      <fa-icon v-else icon="i-far-image" class="w-3/5 h-3/5 block" />
    </div>
    <img
      v-else
      class="w-full h-full"
      :src="src"
      @error="error = true">
  </div>
</template>