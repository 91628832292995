<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    icon: string,
    fixedWidth?: boolean,
    size?: '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x',
  }>()

  const classSize = computed(() => {
    switch (props.size) {
    case '2xs': return 'text-3xs'
    case 'xs': return 'text-xs'
    case 'sm': return 'text-sm'
    case 'lg': return 'text-xl'
    case 'xl': return 'text-2xl'
    case '2xl': return 'text-[2rem]'
    case '1x': return 'text-base'
    case '2x': return 'text-[2rem]'
    case '3x': return 'text-[3rem]'
    case '4x': return 'text-[4rem]'
    case '5x': return 'text-[5rem]'
    case '6x': return 'text-[6rem]'
    case '7x': return 'text-[7rem]'
    case '8x': return 'text-[8rem]'
    case '9x': return 'text-[9rem]'
    case '10x': return 'text-[10rem]'
    default: return ''
    }
  })
</script>

<template>
  <span
    :class="[
      icon,
      {'w-[1.2em]': fixedWidth},
      classSize,
    ]"></span>
</template>